import React, { useState, useEffect } from 'react'
import { Star } from './Icons'
import classNames from 'classnames'
import Tooltip from 'react-tooltip'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import './NewLink.scss'

const NewLink = ({
  label = '',
  link = '',
  addedToVault,
  isFocusable,
  index,
  icon,
  sponsored,
}) => {
  const base64 = icon && icon.base64
  let [azureURLs, setAzureURLS] = useState(icon && icon.azureURLs)

  useEffect(() => {
    setAzureURLS(icon && icon.azureURLs)
  }, [icon])

  return (
    <a
      href={link}
      target="_blank"
      className={classNames('NewLink', `NewLink--${index}`)}
      tabIndex={isFocusable ? 0 : -1}
      onClick={() => {
        trackCustomEvent({
          category: sponsored ? 'New link - sponsored' : 'New link',
          action: 'Click',
          label: label,
        })
      }}
    >
      <article>
        <div className="NewLink_pic">
          {base64 && (
            <img
              src={'data:image/png;base64, ' + base64}
              alt={`${icon.label} logo`}
            />
          )}
          {azureURLs && !base64 && (
            <div
              aria-hidden
              style={{
                backgroundImage: `url(${azureURLs.icon_url})`,
              }}
            />
          )}
        </div>
        <span className="NewLink_label">{label}</span>
        {addedToVault && (
          <>
            <Star
              data-tip
              data-for={`vaultStar_tooltip--${label}`}
              className="vaultStar"
              aria-describedby={`vaultStar_tooltip--${label}`}
            />
            <Tooltip
              id={`vaultStar_tooltip--${label}`}
              effect="solid"
              place="bottom"
              arrowColor="#f0f0f0"
              className="tooltip"
              role="tooltip"
            >
              Added to the repo
            </Tooltip>
          </>
        )}
        {sponsored && <span className="NewLink_sponsored">Sponsored</span>}
      </article>
    </a>
  )
}

export default NewLink
