import React, { useState } from 'react'
import NewLink from './NewLink'
import SignUp from './SignUp'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import { closeSpring, openSpring, getOlderDate, getWindow } from '../utils'
import './NewLinkSectionDay.scss'

const NewLinkSectionDay = ({
  day,
  date,
  nodes,
  isActive,
  index,
  activeDay,
  isStatic,
}) => {
  const variants = {
    hidden: {
      opacity: 0,
      x: activeDay > index ? '-3rem' : '4rem',
      transition: closeSpring,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        ...openSpring,
        delay: 0.11,
      },
    },
  }

  const [isSubbed] = useState(
    getWindow().localStorage.getItem('wdr_subscribed')
  )

  return (
    <motion.section
      initial={isStatic ? 'visible' : 'hidden'}
      animate={isActive ? 'visible' : 'hidden'}
      variants={variants}
      className={classNames(
        'NewLinkSectionDay',
        `NewLinkSectionDay--${nodes.length}`,
        {
          'NewLinkSectionDay--placeholder': day === 'Foo',
          'NewLinkSectionDay--active': isActive,
          'NewLinkSectionDay--static': isStatic,
          'NewLinkSectionDay--subbed': isSubbed,
        }
      )}
    >
      <h2>{isStatic ? getOlderDate(date) : day}</h2>
      <div className="NewLinkSectionDay_links">
        {nodes.slice(0, 5).map((link, index) => (
          <NewLink
            {...link}
            key={link.label}
            isFocusable={isActive}
            index={index}
          />
        ))}
        {!isStatic && (
          <SignUp
            isFocusable={isActive}
            isVisibleOnMobile={nodes.length <= 3}
          />
        )}
      </div>
    </motion.section>
  )
}

export default NewLinkSectionDay
